





















































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditRetirementFundRetirementAgeViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-retirement-age-view-model';

@Component({ name: 'EditRetirementFundRetirementAge' })
export default class EditRetirementFundRetirementAge extends Vue {
  edit_retirement_current_plan_view_model = Vue.observable(
    new EditRetirementFundRetirementAgeViewModel(this),
  );

  mounted() {
    this.edit_retirement_current_plan_view_model.initialize();
  }
}
