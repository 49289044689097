import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import Vue from 'vue';

// Application
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import EditRetirementFundGoalPlanRetirementAgeService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-retirement-age-service';

// Domain
import { RetirementAgeEntity }
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/domain/entities/retirement-age-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditRetirementFundRetirementAgeViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_PERSONAL_INFO_QUERY)
  private readonly get_personal_info_query!: GetPersonalInfoQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
  private readonly retirement_age_service!: EditRetirementFundGoalPlanRetirementAgeService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.edit_retirement_fund_retirement_age';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  readonly min_age_to_retirement = 55;

  readonly max_age_to_retirement = 75;

  plan_information = [
    {
      label: this.translate('current_age'),
      value: '',
    },
    {
      label: this.translate('retirement'),
      value: '',
    },
    {
      label: this.translate('pension'),
      value: '',
    },
  ]

  retirement_age = 0;

  current_user_age = 0;

  pension = 0;

  lifetime_expected = 0;

  investor_profile_id = '';

  initialize = async () => {
    await this.loadInvestorProfile();
    await this.loadUserPersonalInformation();
    await this.loadRetirementFundInvestorGoalInformation();
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  setAgeToRetirement = () => {
    this.plan_information[1].value = `${this.retirement_age - this.current_user_age} ${this.translate('years')}`;
    this.plan_information[2].value = `${this.lifetime_expected - this.retirement_age} ${this.translate('years')}`;
  }

  loadRetirementFundInvestorGoalInformation = async () => {
    try {
      const retirement_age_state = this.getRetirementAgeInformation();
      if (retirement_age_state.current_age) {
        this.current_user_age = retirement_age_state.current_age;
        this.retirement_age = retirement_age_state.retirement_age;
        this.pension = retirement_age_state.pension;
        this.lifetime_expected = retirement_age_state.lifetime_expected;
      } else {
        const { retirement_age_adjusted, lifetime_expected } = await this
          .get_retirement_investor_goal_query.execute();
        this.retirement_age = (retirement_age_adjusted < this.min_age_to_retirement) ? this
          .min_age_to_retirement : retirement_age_adjusted;
        this.lifetime_expected = lifetime_expected;
        this.pension = lifetime_expected - retirement_age_adjusted;
      }
      this.plan_information[0].value = `${this.current_user_age} ${this.translate('years')}`;
      this.plan_information[1].value = `${this.retirement_age - this.current_user_age} ${this.translate('years')}`;
      this.plan_information[2].value = `${this.pension} ${this.translate('years')}`;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_fund_information'));
    }
  }

  loadUserPersonalInformation = async () => {
    try {
      const { person } = await this.get_personal_info_query.execute(this.investor_profile_id);
      this.current_user_age = person.current_age;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_user_personal_information'));
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_id = investor_profile.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investor_profile'));
    }
  }

  setRetirementAgeInformation = (retirement_age: RetirementAgeEntity) => {
    this.retirement_age_service.setRetirementAgeInformation(retirement_age);
  }

  getRetirementAgeInformation = () => (this.retirement_age_service.getRetirementAgeInformation())

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const retirement_age: RetirementAgeEntity = {
      retirement_age: this.retirement_age,
      current_age: this.parseCurrencyToNumber(this.plan_information[0].value),
      retirement: this.parseCurrencyToNumber(this.plan_information[1].value),
      pension: this.parseCurrencyToNumber(this.plan_information[2].value),
      lifetime_expected: this.lifetime_expected,
    };
    this.setRetirementAgeInformation(retirement_age);
    this.view.$emit('nextStep');
  }
}
